import React, { HTMLAttributes, memo } from "react"
import styled from "../context/theme"

const StyledSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  label: SectionWrapper;
`

const StyledInner = styled.div`
  max-width: ${props => props.theme.size.maxWidth}px;
`

export const InnerSection: React.FC<HTMLAttributes<
  HTMLElement
>> = memo(({ children, ...props }) => (
  <StyledInner {...props}>{children}</StyledInner>
))

export const SectionWrapper: React.FC<HTMLAttributes<HTMLElement>> = memo(
  ({ children, ...props }) => {
    return <StyledSectionWrapper {...props}>{children}</StyledSectionWrapper>
  }
)
