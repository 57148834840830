import styled from "../context/theme"
import { memo } from "react"

export const Backdrop = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

const StyledModal = styled.div`
  width: 235px;
  padding: 28px 25px 61px;
  border-radius: 8px;
  text-align: center;
`

export const Modal = memo(({ children, ...props }) => {
  return <StyledModal {...props}>{children}</StyledModal>
})
