import React, { HTMLAttributes, memo, useRef, useEffect, useState } from "react"
import lThrottle from "lodash/throttle"
import { css } from "@emotion/core"

import { LinkButton as LinkButtonOrig } from "./button"
import { InnerSection, SectionWrapper } from "./common"
import styled from "../context/theme"

const Self = styled(SectionWrapper)`
  background-color: ${props => props.theme.colors.pink};
  padding: 50px 40px;
`

const ButtonsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 140px;
`
const FloatingButtons = styled.div<{
  fixed: boolean
  scrollDownStatus: boolean
}>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  bottom: -140px;
  left: 0;
  margin-top: 50px;
  transition: bottom 0.5s;
  padding: 20px 40px;

  ${props =>
    props.scrollDownStatus && props.fixed
      ? css`
          bottom: 0px;
        `
      : css``}

  ${props =>
    props.fixed
      ? css``
      : css`
          position: relative;
          bottom: 0;

          > a {
            width: 205px;
          }
        `}
`

const LinkButton = styled(LinkButtonOrig)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 374px;
  height: 60px;
  border-radius: 8px;

  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
`

const InstagramLink = styled.a``

const StepDescription = styled.p`
  color: ${props => props.theme.colors.white};
  width: 100%;
  white-space: nowrap;
`

const StyledStepImage = styled.img`
  margin-top: 34px;
`

const StyledStepPicture = styled.picture``

const StepImage: React.FC<
  HTMLAttributes<HTMLPictureElement> & {
    alt: string
    src: string
    srcType?: string
    src2?: string
    src2Type?: string
  }
> = ({ alt, src, srcType = "image/webp", src2, src2Type, ...props }) => {
  return (
    <StyledStepPicture {...props}>
      <source srcSet={src} type={srcType} />
      {src2 && <source srcSet={src2} type={src2Type} />}
      <StyledStepImage alt={alt} src={src} />
    </StyledStepPicture>
  )
}

const StepNumber = styled.div<{
  height: number
  number: number
  width: number
}>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-image: url("images/step-${props => props.number}.svg");
  background-repeat: no-repeat;
  background-size: contain;
`

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
`

const SubTitle = styled.h2`
  margin-top: 50px;
  color: ${props => props.theme.colors.white};
`

const TitleLogo = styled.div`
  color: ${props => props.theme.colors.white};
  position: relative;
  width: 100%;
  padding-top: 42.4%;
  background-image: url("images/pride-on-your-face.svg");
  background-repeat: no-repeat;
  background-size: contain;
`

const GuideSection: React.FC<{
  onAddScrollEvents: (event: () => void) => void
  scrollDownStatus: boolean
}> = memo(({ onAddScrollEvents, scrollDownStatus }) => {
  const intersectionTriggerRef = useRef(null)
  const [intersectionPassed, setIntersectionPassed] = useState(false)
  const scrollEventRef = useRef(
    lThrottle(() => {
      if (
        intersectionTriggerRef.current.getBoundingClientRect().top -
          intersectionTriggerRef.current.getBoundingClientRect().height +
          100 <
        0
      ) {
        setIntersectionPassed(true)
      } else {
        setIntersectionPassed(false)
      }
    }, 10)
  )

  useEffect(() => {
    onAddScrollEvents(scrollEventRef.current)
  }, [])

  return (
    <Self>
      <InnerSection>
        <TitleLogo className="ga-scroll" data-ga-section="Pride on your face" />
        <SubTitle>참여 방법</SubTitle>
        <StepWrapper
          className="ga-scroll"
          data-ga-section="Face Painting step_1"
        >
          <StepNumber number={1} width={11.6} height={27} />
          <StepImage
            alt="닷페이스 인스타그램 계정에서 얼굴 아이콘 탭 클릭하기"
            src="images/step-1.png"
            srcType="image/png"
          />
          <StepDescription>
            ① 인스타그램의 닷페이스 계정
            <br />(
            <InstagramLink
              href="https://www.instagram.com/dotface_official"
              target="_blank"
            >
              @dotface_official
            </InstagramLink>
            )에서 얼굴 아이콘 탭을
            <br />
            클릭합니다.
          </StepDescription>
        </StepWrapper>

        <StepWrapper
          className="ga-scroll"
          data-ga-section="Face Painting step_2"
        >
          <StepNumber number={2} width={16} height={29} />
          <StepImage
            alt="마음에 드는 필터를 골라 사용해보기 버튼 클릭하기"
            src="images/step-2.webp"
            src2="images/step-2.gif"
            src2Type="image/gif"
          />
          <StepDescription>
            ② 여러 퀴어 플래그를 모티브로 한<br />
            페이스페인팅을 준비했어요🏳️‍🌈 마음에 드는
            <br />
            스토리 필터를 골라 '사용해보기' 버튼을
            <br />
            클릭해주세요.
          </StepDescription>
        </StepWrapper>

        <StepWrapper
          className="ga-scroll"
          data-ga-section="Face Painting step_3"
        >
          <StepNumber number={3} width={17} height={29} />
          <StepImage
            alt="영상이나 사진을 찍어주세요"
            src="images/step-3.webp"
            src2="images/step-3.gif"
            src2Type="image/gif"
          />
          <StepDescription>
            ③ 영상이나 사진을 찍어주세요! 친구와 함께
            <br />
            손을 흔들거나, 하고 싶은 이야기를 건네도
            <br />
            좋아요.
          </StepDescription>
        </StepWrapper>

        <StepWrapper
          className="ga-scroll"
          data-ga-section="Face Painting step_4"
          ref={intersectionTriggerRef}
        >
          <StepNumber number={4} width={16} height={27} />
          <StepImage
            alt="내 스토리에 공유해주세요"
            src="images/step-4.png"
            srcType="image/png"
          />
          <StepDescription>
            ④ '내 스토리'에 공유해주세요! 닷페이스
            <br />
            계정을 태그해주신다면 스토리가 닷페이스
            <br />
            계정에서 리포스팅될 수 있습니다.
          </StepDescription>
        </StepWrapper>

        <ButtonsWrapper>
          <FloatingButtons
            fixed={!intersectionPassed}
            scrollDownStatus={scrollDownStatus}
          >
            <LinkButton
              className="ga-cta"
              href="https://instagram.com/dotface_official"
              target="_blank"
            >
              필터 사용하기
            </LinkButton>
          </FloatingButtons>
        </ButtonsWrapper>
      </InnerSection>
    </Self>
  )
})

export default GuideSection
