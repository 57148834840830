import { keyframes } from "@emotion/core"
import styled, { CreateStyled } from "@emotion/styled"

type Theme = {
  animations: {
    [key: string]: any
  }
  colors: {
    [key: string]: string
  }
  fontWeight: {
    bold: number
    medium: number
  }
  size: {
    maxWidth: number
  }
}

const fadeInOut = keyframes`
from {
  opacity: 1;
}

50% {
  opacity: 0;
}

to {
  opacity: 1;
}
`

export const theme: Theme = {
  animations: {
    fadeInOut,
  },
  colors: {
    black2: "#353435",
    blue: "#5555ff",
    pink: "#ef588a",
    primary: "#6c19bc",
    purple: "#55009e",
    white: "#ffffff",
  },
  fontWeight: {
    bold: 700,
    medium: 500,
  },
  size: {
    maxWidth: 375,
  },
}

export default styled as CreateStyled<Theme>
