import React from "react"

import Footer from "./footer"
import { Backdrop } from "./modals"
import { useModalStore } from "../context/stores"

const Layout = ({ children }) => {
  const { state } = useModalStore()
  return (
    <>
      <main>{children}</main>
      <Footer />

      {state.modals.length > 0 ? (
        <Backdrop>{state.modals.map(modal => modal)}</Backdrop>
      ) : null}
    </>
  )
}

export default Layout
