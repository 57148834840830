import React, { memo } from "react"
import styled from "../context/theme"

import Logo from "./logo"
import { InnerSection as InnerSectionOrig, SectionWrapper } from "./common"

const Self = styled(SectionWrapper)`
  background: linear-gradient(
    ${props => props.theme.colors.blue},
    ${props => props.theme.colors.pink}
  );
  padding-top: 53px;
  padding-bottom: 50px;
  height: 100vh;
  min-height: 850px;
  label: JumbotronSection;
`

const ArrowBottom = styled.div`
  background-image: url("images/arrow-bottom.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 49px;
  height: 32px;
  animation: ${({ theme }) => theme.animations.fadeInOut} 2s ease infinite
    forwards;
`

const BackgroundImageWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 90%;
  margin-top: 30px;
`

const BackgroundImage = styled.div`
  background-image: url("images/main.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Description = styled.p`
  color: ${props => props.theme.colors.white};
  white-space: nowrap;
`

const InnerSection = styled(InnerSectionOrig)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const SqcfLogo = styled.h1`
  color: ${props => props.theme.colors.white};
  position: relative;
  width: 100%;
  padding-top: 24.9%;
  background-image: url("images/sqcf-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
`

export default memo(() => {
  return (
    <Self>
      <InnerSection 
        className="ga-scroll"
        data-ga-section="Header"
      >
        <SqcfLogo />
        <Logo />
        <BackgroundImageWrapper>
          <BackgroundImage />
        </BackgroundImageWrapper>
        <Description>
          우리의 이야기가 당연한 상식이 될 그날까지,
          <br />
          서울퀴퍼에 닷페이스가 함께합니다.
          <br />
          축제에 빠질 수 없는 페이스 페인팅! 색색의 퀴어
          <br />
          플래그를 인스타그램 필터로 준비했어요.
          <br />내 얼굴 위의 Pride🏳️‍🌈를 뽐내주세요!
        </Description>
        <ArrowBottom />
      </InnerSection>
    </Self>
  )
})
