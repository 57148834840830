import React, { memo, useEffect, useRef, useState, useCallback } from "react"
import lThrottle from "lodash/throttle"
import { graphql } from "gatsby"

import DotfacePeople from "../components/dotface-people"
import GuideSection from "../components/guide-section"
import Jumbotron from "../components/jumbotron"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscriptionFormSection from "../components/subscription-form-section"

const BlogIndex: React.FC = memo(() => {
  const [scrollDownStatus, setScrollDownStatus] = useState(false)
  const lastScrollTopRef = useRef(0)
  const scrollEventListRef = useRef([])
  const scrollEventRef = useRef(
    lThrottle(() => {
      // or window.addEventListener("scroll"....
      var st = window.pageYOffset || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTopRef.current) {
        setScrollDownStatus(true)
      } else {
        setScrollDownStatus(false)
      }
      lastScrollTopRef.current = st <= 0 ? 0 : st // For Mobile or negative scrolling

      scrollEventListRef.current.forEach(e => {
        e()
      })
    }, 100)
  )

  useEffect(() => {
    window.addEventListener("scroll", scrollEventRef.current)
    return () => {
      window.removeEventListener("scroll", scrollEventRef.current)
    }
  }, [])

  const handleAddScrollEvents = useCallback((event: () => void) => {
    scrollEventListRef.current = [event, ...scrollEventListRef.current]
  }, [])

  return (
    <Layout>
      <SEO />
      <Jumbotron />
      <GuideSection
        onAddScrollEvents={handleAddScrollEvents}
        scrollDownStatus={scrollDownStatus}
      />
    </Layout>
  )
})

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
