import React, { memo, AnchorHTMLAttributes, ButtonHTMLAttributes } from "react"
import { css } from "@emotion/core"
import styled from "../context/theme"

const baseStyle = ({ inverted = false, theme }) => css`
  border: 1px solid ${inverted ? theme.colors.white : theme.colors.primary};
  background-color: ${inverted ? theme.colors.white : theme.colors.primary};
  color: ${inverted ? theme.colors.primary : theme.colors.white} !important;
  font-weight: ${theme.fontWeight.bold};
  cursor: pointer;
  text-decoration: none;
`

const StyledButton = styled.button`
  ${baseStyle}
`

const StyledLink = styled.a`
  ${baseStyle};
`

export const Button: React.FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { inverted?: boolean }
> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>
}

export const LinkButton: React.FC<
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    inverted?: boolean
  }
> = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>
}
